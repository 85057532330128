
export const getActiveTable = (active: string | null, prevActive: string | null) => {
    const tableElement = document.getElementById(`Table_${prevActive}`);
    const path = tableElement?.getElementsByTagName("rect")[0];

    if (path) {
        path.style.fill = "#052039"
    }


    const table = document.getElementById(`Table_${active}`);
    const pathActive = table?.getElementsByTagName("rect")[0];

    if (pathActive) {
        pathActive.style.fill = "#00B9E7"
    }

}