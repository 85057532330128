import React, {useEffect} from 'react';
import "./Tables.css"
import {ITables} from "../../interfaces/ISchemes";
import parse from 'html-react-parser';
import {getRect} from "./getRect";

export interface ISchemes{
    tables: ITables[];
    svg?: string | null;
    isResturantView: boolean;
    setActiveTable: (activeTable: string | null) => void
}


const schemeRendering = ({
                             tables,
                             setActiveTable,
                             isResturantView
                         }: ISchemes) => {

    const seats = document.getElementsByClassName("Seats");

    for (let i = 1; i < seats.length + 1; i++) {

        const table = tables.find(t => t.number === i)

        if(table) {
            getRect(table, 0 , setActiveTable, isResturantView)
        }

    }
};

const Schemes = ({isResturantView, svg, tables, setActiveTable}: ISchemes) => {

    useEffect(()=> {
        schemeRendering({tables, setActiveTable, isResturantView});
    },[tables, isResturantView]);

    return <>{svg ? parse(svg) : "Нет схемы"}</> ;
} ;



export default Schemes;