import React from 'react';
import './App.css';
import {Route, Routes} from "react-router-dom";
import Tables from "./pages/Tables/Tables";
import {Group} from "@mantine/core";


function App() {
  return (
    <Group position="center">
        <Routes>
            <Route path="/">
                <Route path=":restaurantGuid/:cruiseId" element={<Tables/>} />
            </Route>
        </Routes>
    </Group>
  );
}

export default App;
