import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {ISchemes} from "../interfaces/ISchemes";
import { BASE_API_URL } from '../configs/api.config';

export const webviewSlice = createApi({
    reducerPath: "webview",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_API_URL,
    }),
    endpoints(builder) {
        return {
            getSeatSchema:  builder.query<ISchemes, any>({
                query: ({restaurantGuid, cruiseId}) => `/api/Restaurant/GetSeatSchema/${restaurantGuid}/${cruiseId}`,
            }),
        };
    }
});

export const {useGetSeatSchemaQuery} = webviewSlice;