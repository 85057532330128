import {configureStore} from "@reduxjs/toolkit";
import {webviewSlice} from "./apiSlice";

export const store = configureStore({
    reducer: {
        [webviewSlice.reducerPath]: webviewSlice.reducer,
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware()
            .concat(
                webviewSlice.middleware
            );
    }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
