import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import style from "../table.module.css";
import Schemes from "./Schemes";
import {getRect} from "./getRect";
import {useGetSeatSchemaQuery} from "../../store/apiSlice";
import { Loader} from "@mantine/core";
import {ITables} from "../../interfaces/ISchemes";
import {getActiveTable} from "../../helpers/helpers";

let occupiedCash: {[key: number]: number} = {}

const Tables = () => {

    const [activeTable, setActiveTable] = useState<string | null>("0");
    const [prevActiveTable, setPrevActiveTable] = useState<string | null>("0");
    const [isResturantView, setIsResturantView] = useState<boolean>(false);
    const {restaurantGuid = "", cruiseId} = useParams<string>()

    const {data, isLoading} = useGetSeatSchemaQuery({restaurantGuid, cruiseId})


    useEffect(() => {
        document.addEventListener("addToTable", addOccupied);
        return () => {
            document.removeEventListener("addToTable", addOccupied);
        };
    }, [data?.tables, occupiedCash, isResturantView])

    useEffect(() => {

        document.addEventListener("removeFromTable", removeOccupied);
        return () => {
            document.removeEventListener("removeFromTable", removeOccupied);
        };
    }, [data?.tables, occupiedCash, isResturantView])

    useEffect(() => {
        document.addEventListener("addAllTables", addAllOccupied);
        return () => {
            document.removeEventListener("addAllTables", addAllOccupied);
        };
    }, [data?.tables, occupiedCash, isResturantView])

    useEffect(() => {
        document.addEventListener("isResturantView", getIsRestaurantView);
        return () => {
            document.removeEventListener("isResturantView", getIsRestaurantView);
        };
    }, [isResturantView])


    const getIsRestaurantView = (event: any) => {
        setIsResturantView(event.detail?.isResturantView)
    }

    const addOccupied = (event: any) => {
        const table = data?.tables.find((el: ITables) => el.number === event.detail.number);
        occupiedCash = {...occupiedCash, [event.detail.number]: event.detail.numberOccupied}
        if (table) {
            getRect(table, event.detail.numberOccupied, setActiveTable, isResturantView)
        }
    }

    const addAllOccupied = (event: any) => {
        event.detail.forEach((detail: {
            number: number
            numberOccupied: number
        }) => addOccupied({detail}))
    }

    const removeOccupied = (event: any) => {
        const table = data?.tables.find((el: ITables) => el.number === event.detail.number);
        if (table) {
            getRect(table, occupiedCash[event.detail.number] -= 1, setActiveTable)
        }
    }


    useEffect(() => {
        getActiveTable(activeTable, prevActiveTable)
        setPrevActiveTable(activeTable)
    }, [activeTable])


    return (
        <div className={style.container}>
            <div className={style.schemes}>
                {isLoading
                    ? <Loader size="xl" variant="dots"/>
                    : <Schemes tables={data?.tables || []}
                               svg={data?.schemaSvg}
                               setActiveTable={setActiveTable}
                               isResturantView={isResturantView}
                    />
                }
            </div>
        </div>
    );
};

export default Tables;